import { useState, useEffect } from 'react';

export const REST_ENDPOINT_SWIFTHUB = 'https://oms-multitenant.testingnow.me/rest/V1/integration/customer/token';
export const REST_ENDPOINT_ADS = 'https://swift-ads.testingnow.me//rest/V1/integration/customer/token';

export const EMAIL_ACCOUNT_ADS = 'yogi.teza@sirclo.com';
export const PASSWORD_ACCOUNT_ADS = 'Password123';

export const EMAIL_ACCOUNT_SWIFTHUB = 'puteri.darmayanti+22@sirclo.com';
export const PASSWORD_ACCOUNT_SWIFTHUB = 'Password123';

export const useHardcodedToken = ({ url = '', email = '', password = '' }) => {
    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getHardcodedToken = () => {
            setLoading(true);
            setError(null);

            const xhr = new XMLHttpRequest();
            const data = JSON.stringify({
                username: email,
                password,
            });

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Content-Type', 'application/json');

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    setLoading(false);
                    if (xhr.status === 200) {
                        setToken(xhr.responseText.replaceAll('"', ''));
                    } else {
                        setError(new Error(`Request failed with status ${xhr.status}`));
                    }
                }
            };

            xhr.send(data);
        };

        if (url && email && password) {
            getHardcodedToken();
        }
    }, [url, email, password]);

    return { token, error, loading };
};

export const getHardcodedToken = ({ url = '', email = '', password = '' }) => new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    const data = JSON.stringify({
        username: email,
        password,
    });

    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                resolve(xhr.responseText.replaceAll('"', ''));
            }
            console.error(new Error(`Request failed with status ${xhr.status}`));
        }
    };

    xhr.send(data);
});

export default {
    getHardcodedToken,
    useHardcodedToken,
};
